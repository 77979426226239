import React, { useState } from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";

import Typography from "../Typography/Typography";
import Paper from "../Paper/Paper.tsx";
import { imageParser } from "../../utils/image.utils";

const BlogCardWrapper = styled.div`
  .blog-card {
    width: 100%;
    height: 100%;
    border-radius: 18px;
    max-width: 360px;
    min-height: 400px;
    border: 1px solid #003034;
    padding: 8px;

    &:hover {
      box-shadow: 8px 8px 0px 0px #003032;
    }

    .paper-container.paper-0 {
      box-shadow: initial;
    }

    @media (max-width: 600px) {
      max-width: 300px;
      min-height: 340px;
    }
  }

  .blog-card .blog-img {
    width: 100%;
    height: auto;
    border-radius: 20px 20px 0 0;
  }

  .blog-card .blog-body {
    padding: 15px 4px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background-color: var(--kenkoPureWhite);
    border-radius: 0 0 20px 20px;
  }

  .blog-card .blog-updated {
    color: #737f8c;
    font-family: Figtree;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }

  .blog-card .blog-time {
    color: #243242;
    font-family: Figtree;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    margin: 10px 0;
  }

  .blog-card .blog-title {
    color: #243242;
    font-family: Figtree;
    font-size: 20px;
    font-weight: 600;

    @media (max-width: 600px) {
      font-size: 12px;
    }
  }
`;

export default function BlogCard({
  image,
  imageLoading,
  title,
  link,
  readTime,
  style,
  updatedAt,
  className,
}) {
  const updatedBefore = Math.ceil(
    Math.abs(new Date() - new Date(updatedAt)) / (1000 * 60 * 60 * 24)
  );

  const [loaded, setLoaded] = useState(false);

  return (
    <BlogCardWrapper>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <Paper
          elevation={5}
          className={["blog-card", className && className].join(" ")}
        >
          <div className="blog-header">
            <img
              src={loaded ? imageParser(image) : imageParser(imageLoading)}
              loading="lazy"
              alt={title}
              className="blog-img"
              onLoad={() => setLoaded(true)}
            />
          </div>

          <div className="blog-body">
            <div>
              {readTime && (
                <Typography variant="caption1" className="blog-updated">
                  Updated: {updatedBefore} days ago
                </Typography>
              )}

              {readTime && (
                <Typography variant="caption2" className="blog-time">
                  {readTime} read
                </Typography>
              )}
            </div>

            <Typography className="blog-title">{title}</Typography>
          </div>
        </Paper>
      </a>
    </BlogCardWrapper>
  );
}

BlogCard.propTypes = {
  /**
   * The cover image of the component
   */
  image: PropTypes.string.isRequired,
  /**
   * The title of the component
   */
  title: PropTypes.string.isRequired,
  /**
   * The live link of the blog
   */
  link: PropTypes.string.isRequired,
  /**
   * The read time of the blog
   */
  readTime: PropTypes.string,
  /**
   * The update history of the blog
   */
  updatedAt: PropTypes.string,
  /**
   * Style object to customize the component
   */
  style: PropTypes.object,
  /**
   * The classnames to add to the component
   */
  className: PropTypes.string,
  width: PropTypes.string,
};
