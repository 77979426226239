export const sliderCls = `
  height: "auto",
  "& .slick-slide": {
    paddingRight: "10px",
    paddingLeft: "10px",
    
  },
  "& .slick-arrow.slick-prev": {
    background: " #F4F7ED",
    height: "fit-content",
    borderRadius: "50%",
    position: "absolute",
    zIndex: "100",
    border: "1px solid #E3EBD2",
  }
  
  .slick-arrow.slick-next {
    background: " #F4F7ED",
    height: "fit-content",
    borderRadius: "50%",
    position: "absolute",
    zIndex: "100",
    marginRight: "1%",
    border: "1px solid #E3EBD2",
  }

  .slick-disabled {
    opacity: 0.5,
    cursor: "auto",
  }
  
  .slick-dots li.slick-active a svg path {
    fillOpacity: 1,
  },
`;

export const blogActionBtn = `
  margin: 50px auto 0 auto;
  width: 266px;
  height: 60px;
  display: flex;
  justify-content: center;
  border-radius: 10px !important;
  font-size: 22px !important;
  font-weight: 600 !important;
  
  @media (max-width: 600px) {
    width: 185px;
    height: 48px;
    font-size: 16px !important;
    margin-top: 0;
  }
`;
