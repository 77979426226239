import { Grid } from "@mui/material";
import styled from "@emotion/styled";
import BlogCard from "../../../shared/components/BlogCard/BlogCard";
import Button from "../../../shared/components/Button/Button";
import CardList from "../../../shared/components/CardList/CardList";
import * as ga from "../../common/UtilsGA";
import { blogListItems } from "./BlogItems";
import { blogActionBtn } from "./BlogSection.styles";
import { desktopPadding } from "..";
import { useViewport } from "../../../shared/hooks";
import { useInView } from "react-intersection-observer";
import { useDispatch } from "react-redux";
import { backendDrivenEvents } from "../../../slices/onboarding.slice";
import { useEffect } from "react";
import { backendEvents } from "../../../shared/constants/backend-events";

const BlogHeading = styled.p`
  color: #243242;
  text-align: center;
  font-family: Figtree;
  font-size: 42px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
`;
// color: "var(--kenkoDeepTeal)",
// textAlign: "center",
// opacity: 0.87,
// margin: 0,

const BlogActionBtn = styled(Button)(blogActionBtn);

export const BlogSection = () => {
  const below600 = useViewport(600).below;
  const { ref, inView } = useInView();
  const dispatch = useDispatch();

  useEffect(() => {
    if (inView) {
      dispatch(
        backendDrivenEvents({
          ...backendEvents.HOMEPAGE_BLOGS_SECTION,
        })
      );
    }
  }, [inView, dispatch]);

  return (
    <div ref={ref}>
      <Grid container style={{ padding: `64px ${desktopPadding}` }}>
        <Grid item xs={12}>
          <BlogHeading style={{ marginBottom: "80px", fontSize: "40px" }}>
            Curated reads from the world of healthcare
          </BlogHeading>
        </Grid>

        <Grid item xs={12}>
          <CardList
            items={blogListItems.map((item) => ({
              id: item.id,
              component: <BlogCard {...item} />,
            }))}
          />
        </Grid>

        <Grid item xs={12}>
          <a
            href={"https://www.blog.kenkohealth.in/blog"}
            target="_blank"
            rel="noreferrer"
          >
            <BlogActionBtn
              label="Read more"
              size={"xl"}
              style={{
                boxShadow: "unset",
                padding: "16px 62px",
                borderRadius: "8px",
                fontWeight: 600,
                fontSize: below600 ? "16px" : "20px",
                marginTop: "56px",
                border: "none",
                fontFamily: "Figtree",
                background: "#FBCE60",
              }}
              onClick={() => {
                ga.event({
                  action: "web_blog_read_more_clicked",
                  params: {
                    Page_Url: window.location.href,
                  },
                });
                window?.Moengage?.track_event("web_blog_read_more_clicked", {
                  Page_Url: window.location.href,
                });
              }}
            />
          </a>
        </Grid>
      </Grid>
    </div>
  );
};

export default BlogSection;
