import React from "react";
import styled from "@emotion/styled";
import PropTypes from "prop-types";
import * as ga from "../../../components/common/UtilsGA";
import { blogListItems } from "../../../components/Home/BlogSection/BlogItems";

const CardListWrapper = styled.div`
  .card-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, 270px);
    gap: 40px;
    justify-content: space-evenly;
  }

  .card-list > * {
    height: max-content;
  }
`;

export default function CardList({ items }) {
  return (
    <CardListWrapper>
      <div className="card-list">
        {items.map((item) => (
          <div
            key={item?.id}
            className="card"
            onClick={() => {
              ga.event({
                action: "web_blog_article_clicked",
                params: {
                  Page_Url: window.location.href,
                  BlogTitle: blogListItems[item?.id - 1].title,
                },
              });
              window?.Moengage?.track_event("web_blog_article_clicked", {
                Page_Url: window.location.href,
                BlogTitle: blogListItems[item?.id - 1].title,
              });
            }}
          >
            {item.component}
          </div>
        ))}
      </div>
    </CardListWrapper>
  );
}

CardList.propTypes = {
  /**
   * The array of items for the list
   */
  items: PropTypes.array.isRequired,
};
