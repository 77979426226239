export const blogListItems = [
  {
    id: 1,
    image: "blog_section/blog1.webp",
    imageLoading: "blog_section/blog1.webp",
    title: "The Impact of OPD Plans on Out-of-Pocket Expenses",
    link: "https://www.blog.kenkohealth.in/post/the-impact-of-opd-plans-on-out-of-pocket-expenses",
    readTime: "4 min",
    updatedAt: "10 Oct 2023",
  },
  {
    id: 2,
    image: "blog_section/blog2.webp",
    imageLoading: "blog_section/blog2.webp",
    title: "Why is it Important to Include OPD Coverage in Your Health Plan?",
    link: "https://www.blog.kenkohealth.in/post/why-is-it-important-to-include-opd-coverage-in-your-health-plan",
    readTime: "4 min",
    updatedAt: "20 Sep 2023",
  },
  {
    id: 3,
    image: "blog_section/blog3.webp",
    imageLoading: "blog_section/blog3.webp",
    title: "7 Reasons Online Medicine Delivery is the Future of HealthCare",
    link: "https://www.blog.kenkohealth.in/post/7-reasons-online-medicine-delivery-is-the-future-of-healthcare",
    readTime: "4 min",
    updatedAt: "20 Sep 2023",
  },
  {
    id: 4,
    image: "blog_section/Easy_way_fit.webp",
    imageLoading: "blog_section/Easy_way_fit_small.webp",
    title: "Thrifty, Easy Ways To Get You Fit",
    link: "https://www.blog.kenkohealth.in/post/thrifty-easy-ways-to-get-you-fit",
    readTime: "3 min",
    updatedAt: "14 Apr 2023",
  },
];
